export class Utils {

  static credits() {
    console.log(`%c
 ______     ______     __  __     __
/\\___  \\   /\\  __ \\   /\\ \\/ /    /\\ \\
\\/_/  /__  \\ \\  __ \\  \\ \\  _"-.  \\ \\ \\
  /\\_____\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\
  \\/_____/   \\/_/\\/_/   \\/_/\\/_/   \\/_/

    Creative Digital Agency - zaki.it\n`, "font-family:monospace");
  }

  static getNS(namespaceName = 'zaux') {
    return window[namespaceName] = window[namespaceName] || {};
  }

  static get isDebug() {
    return Utils.getNS().config.debug === true;
  }

  static prependElements() {
    document.querySelectorAll('[data-prepend-to]').forEach((srcEl) => {
      Array.from(document.querySelectorAll(srcEl.dataset.prependTo)).forEach((destEl) => {
        srcEl.removeAttribute('data-prepend-to');
        srcEl.removeAttribute('data-prepend-clone');
        destEl.prepend(
          srcEl.matches('[data-prepend-clone="true"]') ? srcEl.cloneNode(true) : srcEl
        );
      });
    });
  }

  static prependChildrens() {
    document.querySelectorAll('[data-prepend-childs-to]').forEach((parentEl) => {
      Array.from(parentEl.children).reverse().forEach((srcEl) => {
        Array.from(document.querySelectorAll(parentEl.dataset.prependChildsTo)).forEach((destEl) => {
          destEl.prepend(
            parentEl.matches('[data-prepend-clone="true"]') ? srcEl.cloneNode(true) : srcEl
          );
        });
      });
    });
  }

}
