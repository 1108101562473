import { Component } from "./helpers/component";
import { Svg } from "./helpers/svg";
import { Utils } from "./helpers/utils";
import { Lazyload } from "./helpers/lazyload";
import { Device } from "./helpers/device";
import { Trail } from "./components/trail";
import { Navmain } from "./components/navmain";
import { Slider } from "./components/slider";
import { Events } from "./helpers/events";

// https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
const mqoIsTabletLargePortrait = matchMedia(
  `
  (min-device-width: 1024px) and
  (max-device-width: 1366px) and
  (-webkit-min-device-pixel-ratio: 2) and
  (orientation: portrait)
  `.trim().split('\n').join('').replace(/ +(?= )/g,'')
);

const fnFixTabletLarge = function(e) {

  e = e || mqoIsTabletLargePortrait;

  const el = document.querySelector('meta[name="viewport"]');

  if(!el.dataset.backup) {
    el.setAttribute('data-backup', el.getAttribute('content'));
  }

  const now_ms = Date.now();

  if((now_ms - e.lastTriggerMs) > 1000 || !e.lastTriggerMs ) {
    el.setAttribute('content', e.matches ? 'width=1, height=768, initial-scale=1.4, maximum-scale=1.4, user-scalable=no' : el.dataset.backup);
    e.lastTriggerMs = now_ms;
  }

}

Events.onMqChange(mqoIsTabletLargePortrait, fnFixTabletLarge);
if(mqoIsTabletLargePortrait.matches) {
  fnFixTabletLarge();
}


Utils.credits();

Component.register('Trail', Trail);
Component.register('Navmain', Navmain);
Component.register('Slider', Slider);

if(Device.isFirefox) {
  document.documentElement.classList.add('is-firefox');
}

document.addEventListener("DOMContentLoaded", () => {

  Svg.fixIds(document, '[class*="zaux-ico"]');

  Lazyload.init();

  Utils.prependChildrens();

  Component.initAll();

  // Temp fix for URLS in preview
  document.querySelectorAll('[href^="@root.routes."]').forEach((el) => {
    const href = el.getAttribute('href').split('@root.routes.')[1];
    try {
      const url = Utils.getNS().routes[href];
      if(url !== undefined) {
        el.setAttribute('href', url);
      }
    } catch (error) {
      console.error(`Routes not found: ${error}`);
    }
  });

});
