import { Utils } from "./utils";

export class Lazyload {

  static supportsLoadingAttr = 'loading' in HTMLImageElement.prototype;

  /**
   * Contains a reference to the LazySizes's config JSON
   *
   * @static
   * @memberof Lazyload
   */
  static lsc = null;

  /**
   * @see https://github.com/aFarkas/lazysizes
   */
  static init() {

    this.lsc = window.lazySizesConfig = window.lazySizesConfig || {};
    this.lsc.loadedClass = '-is-loaded';
    this.lsc.lazyClass = 'lazyload';

    this.check();

    if (!this.supportsLoadingAttr && window.lazySizes === undefined) {
      let s = document.createElement('script');
      s.src = Utils.getNS().config.helpers.lazyload.fallbackLibUrl;
      s.async = true;
      document.body.appendChild(s);
    }
  }

  static check(targetRoot, selector) {
    const initLazyClass = '-in-lazy';

    targetRoot = targetRoot || document;
    selector = selector || '[loading=lazy]:not(.'+initLazyClass+')';

    targetRoot.querySelectorAll(selector).forEach((e) => {
      e.classList.add(initLazyClass);
      e.onload = function() {
        // if(Utils.isDebug) {
        //   console.log(this);
        // }
        this.classList.add(Lazyload.lsc.loadedClass);
        this.parentNode.classList.add(Lazyload.lsc.loadedClass);
      };
      if(this.supportsLoadingAttr) {
        e.dataset.src ? e.setAttribute('src', e.dataset.src) : undefined;
        e.dataset.srcset ? e.setAttribute('srcset', e.dataset.srcset) : undefined;
      } else {
        e.classList.add(Lazyload.lsc.lazyClass);
      }
    });
  }

  static forceLoad(el) {
    if(el instanceof HTMLImageElement) {
      el.setAttribute('src', el.dataset.src);
      el.setAttribute('srcset', el.dataset.srcset);
      el.classList.add(this.lsc.loadedClass);
      el.parentNode.classList.add(this.lsc.loadedClass);
      return true;
    }
    return false;
  }

}
