export class Svg {

  /**
   * Alter the SVG in order to prevent duplication of IDs.
   *
   * @static
   * @param {*} [elParent=document]
   * @param {boolean} [selectorElementsToSkip=false]
   * @memberof Svg
   */
  static fixIds(elParent = document, selectorElementsToSkip = false) {
    elParent.querySelectorAll('svg' + (selectorElementsToSkip ? `:not(${selectorElementsToSkip})` : '')).forEach((svg, i) => {
      svg.querySelectorAll('[data-id]').forEach((e, y) => {
        const newId = e.dataset.id + '_' + i + '_' + y;
        e.setAttribute('id', newId);
        ['fill', 'clip-path'].forEach((attrName) => {
          svg.querySelectorAll(`[${attrName}="url(#${e.dataset.id})"]`).forEach((ef) => ef.setAttribute(attrName, ef.getAttribute(attrName).replace(e.dataset.id, newId) ));
        });
        e.removeAttribute('data-id');
      });
    });
  };

}
