import gsap from "gsap/all";
import { Dom } from "../helpers/dom";
import { Events } from "../helpers/events";

export class Navmain {

  static cssClass = 'c-navmain';

  static propsCommonAniNav1 = {
    duration: 0.7,
    immediateRender: true,
    ease: "expo.inOut"
  };

  constructor($el = null) {
  }

  static closeSubnavs() {

    Array.from(document.querySelectorAll(`.${Navmain.cssClass}__item--has-childs.-open`)).forEach((el) => {
      let $parent = el.closest(`.${Navmain.cssClass}__item--has-childs`);
      $parent.classList.remove('-open');
      gsap.to(el.querySelector(`ul`), {
        ...Navmain.propsCommonAniNav1,
        height: 0,
        opacity: 1
      });
    });

  }

  /**
   * Called once per kind of component
   */
  static onBoot() {

    Events.delegate.on('click', `.${Navmain.cssClass}__btn-toggle-menu`, (event) => {
      const classToToggle = `${Navmain.cssClass}--state-nav-open`;

      if(document.body.classList.contains(classToToggle)) {
        Navmain.closeSubnavs();
      }

      document.body.classList.toggle(classToToggle);
    });

    ((eventSelector = `.${Navmain.cssClass}__item--has-childs > a`) => {
      Events.delegate.on('click', eventSelector, (event) => {
        event.preventDefault();

        let $target = Dom.getTarget(event.target, eventSelector);
        let $ul = Dom.getNext($target, (e) => e.matches('ul'));
        let $parent = $ul.closest(`.${Navmain.cssClass}__item--has-childs`);

        if(!$parent.classList.contains('-open')) {

          Navmain.closeSubnavs();

          $parent.classList.add('-open');

          gsap.to($ul, {
            ...Navmain.propsCommonAniNav1,
            height: "auto",
            opacity: 1
          });

        } else {

          $parent.classList.remove('-open');

          gsap.to($ul, {
            ...Navmain.propsCommonAniNav1,
            height: 0,
            opacity: 0
          });

        }

      });
    })();

  }

};
