import { default as Delegate } from "eventslibjs/src/js/events/events.js";

export class Events {

  /**
   *
   *
   * @static
   * @param {*} el
   * @param {*} eventsList
   * @param {*} fn
   * @param {boolean} [options=false]
   * @memberof Events
   */
  static on(el, eventsList, fn, options = false) {
    eventsList.split(' ').forEach(e => el.addEventListener(e, fn, options));
  }

  /**
   *
   *
   * @static
   * @param {*} el
   * @param {*} eventsList
   * @param {*} fn
   * @param {boolean} [options=false]
   * @memberof Events
   */
  static off(el, eventsList, fn, options = false) {
    eventsList.split(' ').forEach(e => el.removeEventListener(e, fn, options));
  }

  /**
   *
   *
   * @static
   * @param {*} mqo
   * @param {*} fn
   * @returns
   * @memberof Events
   */
  static onMqChange(mqo, fn) {
    try {
      // Chrome & Firefox
      return mqo.addEventListener('change', fn) || true;
    } catch (e) {
      try {
        // Safari
        return mqo.addListener(fn) || true;
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  }

  static get delegate() {
    return Delegate;
  }

}
