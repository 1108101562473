import Swiper, { EffectFade, Navigation, Pagination } from 'swiper';

export class Slider {

  static cssClass = 'c-slider';

  constructor($el = null) {

    // Configure Swiper to use modules
    Swiper.use([EffectFade, Navigation, Pagination]);

    this.$el = $el;

    this.defaultConfig = {};

    // Creates the config JSON
    this.config = $el.dataset.config ? {...this.defaultConfig, ...JSON.parse($el.dataset.config)} : this.defaultConfig;

    this.$wrapper = $el.querySelector(`.${Slider.cssClass}__wrapper`);
    this.$slider = $el.querySelector(`.${Slider.cssClass}__instance`);

    // Init Swiper
    this.slider = new Swiper(this.$slider, this.config);

  }

};
