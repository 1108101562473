import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Device } from "../helpers/device";
import { Dom } from "../helpers/dom";
import { Events } from "../helpers/events";
import { Utils } from "../helpers/utils";

export class Trail {

  static cssClass = 'c-trail';
  static Config = null;

  constructor($el = null) {

    // Grabs the config from the HTML document
    Trail.Config = Utils.getNS().config.components.trail;

    this.$proxy = $el.querySelector(`.${Trail.cssClass}__proxy`);
    this.$slides = $el.querySelectorAll(`.${Trail.cssClass}__slide`);
    this.$scrollArea = $el.querySelector(`.${Trail.cssClass}__scroll-area`);

    this.timerResetDelayMs = 600;
    this.timerReset = null;
    this.hasMomentumScrolling = !Device.isIOS;

    // if(Utils.isDebug) {
    //   console.info(`isIOS : ${Device.isIOS}`);
    //   console.info(`hasMomentumScrolling : ${this.hasMomentumScrolling}`);

    //   alert(`isIOS : ${Device.isIOS}`);
    //   alert(`hasMomentumScrolling : ${this.hasMomentumScrolling}`);
    // }

    gsap.registerPlugin(ScrollTrigger, Draggable);

    this.mqoIsDesktop = matchMedia(`(min-width: ${Utils.getNS().config.breakpoints.lg}px)`);

    this.mqoIsDesktopSmall = matchMedia(
      `
      (max-height: ${Utils.getNS().config.breakpoints.lg-1}px) and
      (min-width: ${Utils.getNS().config.breakpoints.lg}px)
      `.trim().split('\n').join('').replace(/ +(?= )/g,'')
    );

    this.mqoIsTabletLargePortrait = matchMedia(
      `
      (min-width: 1024px) and
      (max-height: 1366px) and
      (orientation: portrait) and
      (-webkit-min-device-pixel-ratio: 1.5)
      `.trim().split('\n').join('').replace(/ +(?= )/g,'')
    );

    // console.log(`mqoIsDesktopSmall:
    //   ${this.mqoIsDesktopSmall.matches}
    // `);

    // Only on large-desktop devices
    if(this.mqoIsDesktop.matches && !this.mqoIsTabletLargePortrait.matches) {
      this.init();
    }

    // Added in order to handle the resize of some `trail__section` on some tablet landscape or small desktop devices.
    Events.onMqChange(this.mqoIsDesktopSmall, (e) => {
      if(window.innerWidth >= Utils.getNS().config.breakpoints.lg) {
        // console.log(`mqoIsDesktopSmall:
        //   ${e.matches}
        // `);
        // this.destruct();
        // this.init();
        this.reset();
      }
    });

    Events.onMqChange(this.mqoIsDesktop, (e) => {
      if (e.matches) {
        this.init();
      } else {
        if(this.timerReset) {
          clearTimeout(this.timerReset);
          this.timerReset = null;
        }
        setTimeout(this.destruct.bind(this), this.timerResetDelayMs+100);
      }
    });

  }

  destruct() {
    if(this.isMomentumScrollingEnabled) {
      // if(Utils.isDebug) {
      //   console.info(`Destructing ...`);
      // }

      Events.off(window, 'resize orientationchange', this.onResize );

      this.hScroll.kill();
      this.draggable.kill();
    }
  }

  onResize(e) {
    if(this.timerReset) {
      clearTimeout(this.timerReset);
      this.timerReset = null;
    }

    // Updates only if resize is horizontal
    if(this.initWindowW !== window.innerWidth) {
      this.timerReset = setTimeout(() => {
        // this.initWindowW = window.innerWidth;
        this.reset();
      }, this.timerResetDelayMs);
    }
  }

  reset() {
    this.initWindowW = window.innerWidth;

    // Saves the scroll position in order to restore it later
    this.currentScroll = document.documentElement.scrollTop;

    this.destruct();

    if(this.mqoIsDesktop.matches && !this.mqoIsTabletLargePortrait.matches) {
      this.init();

      // Restores the scroll position
      window.scrollTo(0, this.currentScroll);
    }

  }

  init() {

    // if(Utils.isDebug) {
    //   console.info(`Init ...`);
    // }

    const _this = this;

    // Saves the viewport size for a late check
    this.initWindowW = window.innerWidth;

    Events.on(window, 'resize orientationchange', this.onResize.bind(this) );

    this.calcSizes();
    this.calcScrollArea();

    if(this.hasMomentumScrolling) {

      // let dragRatio = this.$scrollArea.offsetWidth / (window.innerWidth * (this.$slides.length - 1));
      let dragRatio = 2;
      // let scrollableWidth = this.$scrollArea.offsetWidth - window.innerWidth - document.querySelector('.c-header').offsetWidth;
      let scrollableWidth = this.$scrollArea.offsetWidth - window.innerWidth;

      // if(Utils.isDebug) {
      //   console.info(`ScrollArea width: ${this.$scrollArea.offsetWidth}`);
      //   console.info(`Drag ratio: ${dragRatio}`);
      // }

      const scrollTween = gsap.to(this.$slides, {
        x: -scrollableWidth,
        ease: "none"
      });

      // scrollTween.progress(1);

      this.hScroll = ScrollTrigger.create({
        animation: scrollTween,
        trigger: this.$scrollArea,
        pin: true,
        scrub: 1,
        end: scrollableWidth
      });

      this.draggable = Draggable.create(this.$proxy, {
        force3D: true,
        trigger: this.$scrollArea,
        type: "x",
        onPress() {
          // console.log( _this.hScroll.scroll() );
          this.startScroll = _this.hScroll.scroll();
        },
        onDrag() {
          _this.hScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
        }
      })[0];

    }

  }

  calcSizes() {
    Dom.childrens(this.$slides[0], `[class*="${Trail.cssClass}__section"]`).forEach(el => {

      if(el.classList.contains(`${Trail.cssClass}__section--w-full`)) {
        let targetW = window.innerWidth >= Trail.Config.minwidthSectionFull ? window.innerWidth : Trail.Config.minwidthSectionFull;
        el.style.width = `${targetW}px`;
      } else if(el.classList.contains(`${Trail.cssClass}__section--w-fullforce`)) {
        el.style.width = `${window.innerWidth}px`;
      } else if(el.classList.contains(`${Trail.cssClass}__section--w-stripe`)) {
        el.style.width = `52px`;
      } else if('colSmSize' in el.dataset && this.mqoIsDesktopSmall.matches) {
        el.style.width = `${parseFloat(el.dataset.colSmSize) * Trail.Config.widthSectionColBase}px`;
      } else if('colSize' in el.dataset) {
        el.style.width = `${parseFloat(el.dataset.colSize) * Trail.Config.widthSectionColBase}px`;
      }

    });
  }

  calcScrollArea() {
    this.$scrollArea.style.width = `${this.sectionsTotalWidth}px`;
    this.$slides[0].style.width = `${this.sectionsTotalWidth}px`;
  }

  get isMomentumScrollingEnabled() {
    return this.hScroll && this.draggable;
  }

  get sectionsTotalWidth() {
    return Dom.childrens(this.$slides[0], `[class*="${Trail.cssClass}__section"]`).reduce((total, e) => total + e.offsetWidth, 0);
  }

};
